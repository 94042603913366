import React from "react"
import Layout from "../components/layouts"
import lost from '../images/lost.png'

export default () => (
  <Layout>
    <div className="not-found">
		<img src={lost} alt="V-ați rătăcit"></img>
  		<h1>404</h1>
  		<h2>V-ați rătăcit</h2>
  		<p><a href="/">Găsiți o destinație</a></p>
  	</div>
  </Layout>
)
